<script>
import constants from '@shared/config/constants';

export default {
  props: {
    alt: {
      type: String,
      default: '',
    },
    buyableType: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(constants.BUYABLE_TYPE).includes(value);
      },
    },
    buyable: {
      type: Object,
      required: true,
    },
  },
  computed: {
    picture() {
      if (this.buyable.picture && !this.buyable.picture.startsWith('http')) {
        return `${this.$env.apiResourcesURL}/${this.buyable.picture}`;
      }

      if (!this.buyable.picture) {
        this.$emit('img-load');
      }

      return this.buyable.picture;
    },
  },
};
</script>

<template>
  <img :src="picture" :alt="alt || buyable.name" />
</template>
