<script>
import BuyableImg from '@shared/components/BuyableImg.vue';
import RegisterForm from '@school/components/RegisterForm.vue';
import CertificateButton from '@school/components/CertificateButton.vue';
import moment from 'moment';

export default {
  props: {
    trainings: {
      type: Array,
      required: true,
    },
  },
  components: { BuyableImg, RegisterForm, CertificateButton },
  data: () => ({ registerFormIsOpen: false }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
  },
  methods: {
    onEnroll(training) {
      this.registerFormIsOpen = false;
      training.customer_type = 'STUDENT';
      this.$buefy.dialog.alert(this.$t('customer_trainings_list_enrollment_success', { name: training.name }));
    },
    showExpirationDelay(customerTraining) {
      return customerTraining.expiration
        && moment().isBefore(customerTraining.expiration)
        && customerTraining.customer_type === 'STUDENT';
    },
  },
  i18n: {
    messages: {
      en: {
        customer_trainings_list_no_trainings: 'You have not joined any training(s) in this training center yet.',
        customer_trainings_list_last_consulted: 'Last consulted',
        customer_trainings_list_enrolled_on_full: 'Enrolled on',
        customer_trainings_list_enrolled_on: 'Enrolled on',
        customer_trainings_list_expires_on_full: 'Expires on',
        customer_trainings_list_expires_on: 'Expires on',
        customer_trainings_list_lessons: 'lessons',
        customer_trainings_list_continue: 'Continue',
        customer_trainings_list_start: 'Start',
        customer_trainings_list_view_sheet: 'View details',
        customer_trainings_list_restart_training: 'Restart training',
        customer_trainings_list_finalize_enrollment: 'Finalize my enrollment',
        customer_trainings_list_blocked_by_teacher: 'Training locked by the trainer',
        customer_trainings_list_access_expired: 'Access to the training has expired',
        customer_trainings_list_enrollment_success: 'You can now access the training {name} in the "My trainings" tab of the dashboard.',
        at: 'at',
      },
      fr: {
        customer_trainings_list_no_trainings: 'Vous n\'avez rejoint aucune formation dans cet espace.',
        customer_trainings_list_last_consulted: 'Dernière consultation',
        customer_trainings_list_enrolled_on_full: 'Inscrit le',
        customer_trainings_list_enrolled_on: 'Inscrit le',
        customer_trainings_list_expires_on_full: 'Expire le',
        customer_trainings_list_expires_on: 'Expire le',
        customer_trainings_list_lessons: 'leçons',
        customer_trainings_list_continue: 'Continuer',
        customer_trainings_list_start: 'Commencer',
        customer_trainings_list_view_sheet: 'Voir la fiche',
        customer_trainings_list_restart_training: 'Revoir la formation',
        customer_trainings_list_finalize_enrollment: 'Finaliser mon inscription',
        customer_trainings_list_blocked_by_teacher: 'Formation bloquée par le formateur',
        customer_trainings_list_access_expired: 'L\'accès à la formation a expiré',
        customer_trainings_list_enrollment_success: 'Vous pouvez désormais accéder à la formation {name} dans l\'onglet "Mes formations" du tableau de bord.',
        at: 'à',
      },
    },
  },
};
</script>

<template>
  <section class="ctlcomp">
    <p v-if="trainings.length <= 0" class="notification">
      {{ $t('customer_trainings_list_no_trainings') }}
    </p>
    <template v-else>
      <article v-for="one in trainings" :key="one.uuid" class="box media is-block-mobile">
        <div class="media-left">
          <figure class="image w-200 ofit-cover bradius-8">
            <BuyableImg
              v-if="one.picture"
              :buyable-type="$constants.BUYABLE_TYPE.TRAINING"
              :buyable="one"
            />
            <span v-else class="is-block p-10 has-background-light is-custom has-text-centered">
              <b-icon icon="chalkboard-teacher" size="is-large" />
            </span>
          </figure>
        </div>
        <div class="media-content mb-0">
          <div class="content">
            <p class="is-size-8" :class="showExpirationDelay(one) ? 'mb-0' : ''">
              <b-icon class="-ml-1" icon="calendar-check" pack="far" />
              <template v-if="one.last_activity_at">
                {{ $t('customer_trainings_list_last_consulted') }} {{ one.last_activity_at | momentFromUTC | moment('from') }}
                •
              </template>
              <b-tooltip>
                <template #content>
                  {{ $t('customer_trainings_list_enrolled_on_full') }} {{ one.created_at | momentFromUTC | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}
                </template>
                {{ $t('customer_trainings_list_enrolled_on') }} {{ one.created_at | momentFromUTC | moment('DD/MM/YY') }}
              </b-tooltip>
            </p>
            <p v-if="showExpirationDelay(one)" class="is-size-8">
              <b-icon class="-ml-1" icon="clock" pack="far" />
              <b-tooltip>
                <template #content>
                  {{ $t('customer_trainings_list_expires_on_full') }} {{ one.expiration | momentFromUTC | moment('DD/MM/YY [' + $t('at') +  '] HH:mm') }}
                </template>
                {{ $t('customer_trainings_list_expires_on') }} {{ one.expiration | momentFromUTC | moment('DD/MM/YY') }}
              </b-tooltip>
            </p>
            <h3 class="mt-0 is-size-6-touch">
              <router-link
                class="color-inherit"
                :class="{'pevents-none': one.customer_type == 'BLOCKED'}"
                :to="{name: 'mytraining', params: {slug: one.slug}}"
              >
                {{ one.name }}
              </router-link>
            </h3>
            <p v-if="one.summary" class="is-size-7-touch">
              {{ one.summary }}
            </p>
            <template v-if="one.customer_type == 'STUDENT' || one.customer_type == 'BLOCKED'">
              <p class="is-flex is-align-items-center is-size-8">
                <span class="mr-5">
                  {{ one.completed_module_count }} / {{ one.total_module_count }} {{ $t('customer_trainings_list_lessons') }}
                  ({{ Math.round(one.completed_module_count / one.total_module_count * 100) }}%)
                </span>
                <span class="is-flex-grow-1">
                  <b-progress
                    class="w-full"
                    type="is-success"
                    size="is-small"
                    :value="one.completed_module_count"
                    :max="one.total_module_count"
                  />
                </span>
              </p>
            </template>
          </div>

          <nav class="ctlcomp_cta is-flex is-justify-content-flex-end mt-10 has-text-right">
            <template v-if="one.customer_type == 'STUDENT' || one.customer_type == 'BLOCKED'">
              <p v-if="one.customer_type == 'STUDENT'">
                <b-button
                  v-if="one.started_at"
                  type="is-text"
                  tag="router-link"
                  :to="{name: 'mytraining', params: {slug: one.slug}}"
                >
                  {{ $t('customer_trainings_list_view_sheet') }}
                </b-button>
                <template v-if="!one.completed_at">
                  <b-button
                    v-if="one.started_at"
                    type="is-primary is-custom"
                    tag="router-link"
                    :to="{name: 'mytrainingitem', params: {slug: one.slug, id: one.last_activity_training_item_id}}"
                  >
                    {{ $t('customer_trainings_list_continue') }}
                  </b-button>
                  <b-button
                    v-else
                    type="is-primary is-custom"
                    tag="router-link"
                    :to="{name: 'mytrainingitem', params: {slug: one.slug, id: one.first_training_item_id}}"
                  >
                    {{ $t('customer_trainings_list_start') }}
                  </b-button>
                </template>
              </p>
              <template v-if="one.completed_at">
                <p v-if="!store.certificate_options.disabled || one.certificate_path">
                  <CertificateButton :training="one" />
                </p>
                <p v-else key="see_again">
                  <b-button
                    type="is-primary is-custom"
                    tag="router-link"
                    :to="{name: 'mytraining', params: {slug: one.slug}}"
                  >
                    {{ $t('customer_trainings_list_restart_training') }}
                  </b-button>
                </p>
              </template>
            </template>
            <template v-else>
              <b-button
                type="is-primary is-custom"
                tag="router-link"
                :to="{name: 'training', params: {slug: one.slug}}"
              >
                {{ $t('customer_trainings_list_finalize_enrollment') }}
              </b-button>
              <RegisterForm
                v-if="registerFormIsOpen"
                :buyable="one"
                @enroll="onEnroll(one)"
                @complete="registerFormIsOpen = false"
                @close="registerFormIsOpen = false"
              />
            </template>

            <template v-if="one.customer_type == 'BLOCKED' && one.blocked_reason == 'BY_TEACHER'">
              <div class="tag is-dark">
                {{ $t('customer_trainings_list_blocked_by_teacher') }}
              </div>
            </template>
            <template v-else-if="one.customer_type == 'BLOCKED' && one.blocked_reason == 'BY_EXPIRATION'">
              <div class="tag is-dark">
                {{ $t('customer_trainings_list_access_expired') }}
              </div>
            </template>
          </nav>
        </div>
      </article>
    </template>
  </section>
</template>
