<script>
import linkTypes from '@shared/assets/data/social_links.json';
import TrainingsList from '@school/components/TrainingsList.vue';
import CustomerTrainingsList from '@school/components/CustomerTrainingsList.vue';
import BundlesList from '@school/components/BundlesList.vue';

export default {
  name: 'Dashboard',
  components: { TrainingsList, CustomerTrainingsList, BundlesList },
  static: { linkTypes },
  data: () => ({ isFetching: false }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
    authCustomer() {
      return this.$store.getters['auth/customer'];
    },
    customerTrainingsAsStudentAndBlocked() {
      return this.$store.getters['auth/customerTrainingsAsStudentAndBlocked'];
    },
    customerTrainingsAsAbandoned() {
      return this.$store.getters['auth/customerTrainingsAsAbandoned'];
    },
  },
  created() {
    this.isFetching = true;
    this.$store.dispatch('auth/fetch')
      .finally(() => {
        this.isFetching = false;
      });
  },
  i18n: {
    messages: {
      fr: {
        dashboard_welcome: 'Bienvenue',
        dashboard_description: 'Retrouvez et suivez l\'avancement de toutes vos formations ici',
        dashboard_tab_my_trainings: 'Mes formations ({count})',
        dashboard_tab_pending: 'En attente ({count})',
        dashboard_tab_all_trainings: 'Toutes les formations ({count})',
        dashboard_tab_all_packs: 'Tous les packs ({count})',
      },
      en: {
        dashboard_welcome: 'Welcome',
        dashboard_description: 'Get new trainings and follow up your progress here',
        dashboard_tab_my_trainings: 'My trainings ({count})',
        dashboard_tab_pending: 'Pending ({count})',
        dashboard_tab_all_trainings: 'All trainings ({count})',
        dashboard_tab_all_packs: 'All bundles ({count})',
      },
    },
  },
};
</script>

<template>
  <div class="dcomp page px-5 py-10">
    <h1 class="title is-custom has-text-centered is-size-6-touch">
      {{ $t('dashboard_welcome') }} <strong class="tdecoration-underline">{{ authCustomer.firstname }}</strong>
    </h1>
    <div class="content has-text-centered">
      <p>{{ $t('dashboard_description') }}</p>
    </div>

    <RenderlessToggle #default="{ isActive, setActive }" active="0" length="4">
      <div>
        <nav class="tabs">
          <ul>
            <li :class="{ 'is-active': isActive == 0 }">
              <a @click="setActive(0)">
                {{ $t('dashboard_tab_my_trainings', { count: customerTrainingsAsStudentAndBlocked.length }) }}
              </a>
            </li>
            <li :class="{ 'is-active': isActive == 1 }">
              <a @click="setActive(1)">
                {{ $t('dashboard_tab_pending', { count: customerTrainingsAsAbandoned.length }) }}
              </a>
            </li>
            <li :class="{ 'is-active': isActive == 2 }">
              <a @click="setActive(2)">
                {{ $t('dashboard_tab_all_trainings', { count: store.trainings.length }) }}
              </a>
            </li>
            <li v-if="store.bundles.length" :class="{ 'is-active': isActive == 3 }">
              <a @click="setActive(3)">
                {{ $t('dashboard_tab_all_packs', { count: store.bundles.length }) }}
              </a>
            </li>
          </ul>
        </nav>
        <CustomerTrainingsList v-if="isActive == 0" :trainings="customerTrainingsAsStudentAndBlocked" />
        <CustomerTrainingsList v-else-if="isActive == 1" :trainings="customerTrainingsAsAbandoned" />
        <TrainingsList v-else-if="isActive == 2" :trainings="store.trainings" :total="store.trainings.length"
          :has-title="false" :has-price="false" is-small />
        <BundlesList v-else-if="isActive == 3" :bundles="store.bundles" :has-title="false" :has-price="false"
          :total="store.bundles.length" is-small />
      </div>
    </RenderlessToggle>
  </div>
</template>
